.overall {
  .explain{
     //display: flex;
     //justify-content: space-between;
    position: absolute;
    top: 120px;
    right: 25px;
    .flag-item{
       width: 200px;
       margin-bottom: 5px;
       display: flex;
       justify-content: space-between;
      font-weight: 600;
       .status {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            //background-color: green;
            margin-right: 5px;
          }
    }
  }
  .itemContainer {
    display: flex;
    justify-content: space-between;

    .largeitem {
      width: 30%;
      //margin-right: 20px;
      margin-bottom: 5px;
      border: #c2c2c2 1px solid;

      .smallTitle {
        margin-left: 5px;
        display: inline-block;
        color: #ffad26;
        font-weight: 700;
      }

      .smallitem {
        width: 100%;

        .microitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;

          .itemtitle {
            font-weight: 700;
            line-height: 16px;
            vertical-align: center;
            margin-left: 5px;
          }

          .status {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            //background-color: green;
            margin-right: 5px;
          }
        }


      }

    }


  }


}