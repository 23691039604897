.sketch {
  position: relative;
  width: 100%;
  height: 220px;
  //background-color: #dcdcdc;
  .sk-left {
    position: absolute;
    top: 15px;
    left: 170px
  }

  .sk-right {
    position: absolute;
    top: 178px;
    left: 170px
  }

  .car {
    position: absolute;
    top: 35px;
    left: 160px;
  }
  .pos-tire{
    position: absolute;
    height: 180px;
    width: 360px;
    //border: solid 1px;
    top:18px;
    left: 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  .gl{
    position: absolute;
    height: 20px;
    width: 100%;
    background-color: #e5e5e5;
    bottom: -5px;
  }
}