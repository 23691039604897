
.we-da-co{
  width: 90px;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;

}
.we-num{
  font-size: 5px;
  //background-color: #ffa71a;
  text-align: center;
  width: 15px;


}
