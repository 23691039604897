
.w-car-con{
  width: 100px;
  height: 125px;
  background-color: #fff;
  text-align: center;

.w-car-cen{
 margin-top: 15px;
  width: 43px;
  height: 95px;


}

}



