.it{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
  .it-left .it-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ttle{
      font-weight: 700 ;
    }
    .note{
      font-size: 5px !important;
    }
  #dot{
    margin: auto;
    width: 16px;
    height: 16px;
    border-radius:50%;
    //background-color: #32ff0e;
  }
}