.Ty-report {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 1320px;
  font-size: 8px;
  background: #f0f2f5;
  .Ty-card {
    position: relative;
    width: 830px;
    min-height: 1123px;
    background: #fff;
    padding: 16px;
    height: 1320px;

  }
}
  .Ty-noprint {
    position: absolute;
    left: 20px;
    top: 10px;
  }

@page {
  size: A4 ;
  margin-top: 0px;
  content: "Our Cats";
}
@media print {
  body{
    -webkit-print-color-adjust: exact;
  }
  .Ty-report{
    height: 1320px;
    .Ty-card{
      height: 100%;
      padding-left: 28px;
      padding-right: 28px;
    }
  }
  .Ty-noprint {
    display: none;
  }
}