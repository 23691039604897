.usa-report {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 1100px;
  //font-size: 8px;
  background: #f0f2f5;


.usa-card {
    position: relative;
    width: 830px;
    min-height: 1123px;
    background: #fff;
    padding: 25px;
    height: 1100px;
  }}

  .usa-noprint {
    position: absolute;
    left: 20px;
    top: 10px;
  }

  @page {
  size: A4 ;
  margin-top: 0;
  content: "Our Cats";
}
@media print {
  body{
    -webkit-print-color-adjust: exact;
  }
  .usa-report{
    height: 1100px;
    .usa-card{
      height: 100%;
    }
  }
  .usa-noprint {
    display: none;
  }
}