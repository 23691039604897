.car-cen{
  position: relative;
  width: 43px;
  height: 95px;
  z-index: 99;
  margin: auto;
}

.car-lf{
  position: relative;
  width: 12px;
  height: 25px;
  top: -25px;
  left: 20px
}
.car-rf{
  position: relative;
  width: 12px;
  height: 25px;
  top:-25px;
  left: -7px;
}
.car-lb{
  position: relative;
  width: 12px;
  height: 25px;
  top: 18px;
  left: 8px
}
.car-rb{
  position: relative;
  width: 12px;
  height: 25px;
  top: 18px;
  left: -20px;
}