

  .dill-wear-con {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
    margin: auto;


    .dill-wear-lf {
      width: 35%;
      margin-top: 20px;
    }

    .dill-wear-rf {

      width: 35%;
      margin-top: 20px;
    }

    .dill-wear-lb {

      width: 35%;
      margin-top: 70px;
    }

    .dill-wear-rb {

      width: 35%;
      margin-top: 70px;
    }

    .dill-wear-cen {
      position: absolute;
      margin-top: 65px;
      left: 220px;

    }


    .con-tire {
      width: 99px;
      height: 46px;
    }

    .con-le-con {
      margin-left: 5px;
    }

    .con-num {
      width: 99px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }













.title {
    display: flex;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    align-items: center;
    &::before {
      position: absolute;
      content: "";
      left: -14px;
      top: 50%;
      width: 3px;
      height: 80%;
      transform: translateY(-50%);
      background: #ffad26;
    }
  }