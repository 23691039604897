.annotation{
  width: 230px;
  height: 90px;
  background-color: #e5e5e5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ann-con{
    margin: auto;
    .n-dot{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius:50%;
  }
    .c-text{
      font-weight: 500;
      font-size: 7px;
      padding-left: 5px;
    }
  }

}