
.suggestion {
  height: 100px;
  margin-left: 15px;
  margin-right: 15px;
   margin-top: 10px;
}

.su-content {
  position: relative;
  background-color: #fff4e1;
  height: 88px;
  top: 6px;

  .lor-title {
     position: relative;
     top: 5px;
   span{
     display: inline-block;
     font-weight: bold;
     font-size: 16px;
     line-height: 16px;
     margin-left: 5px;
   }
    .ic{
      width: 3px;
      height: 18px;
      margin-right: 8px;
      background-color: #ffad26;
    }
  }
}

