.wear{
  margin-bottom: 5px;
  .wear-two {
  width: 100%;
  text-align: center;

  img {
    width: 750px;
    height: 30px;
  }
}

.wear-three {
  position: relative;
  //background-color: #f9edff;
  width: 100%;
  height: 350px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .wear-lf {
    width: 50%;
    height: 50%;
    border-right: #e5e5e5 1.5px solid;
    border-bottom: #e5e5e5 1.5px solid;
  }

  .wear-rf {
    width: 50%;
    height: 50%;

  }

  .wear-lb {
    width: 50%;
    height: 50%;

  }

  .wear-rb {
    width: 50%;
    height: 50%;
    border-left: #e5e5e5 1.5px solid;
    border-top: #e5e5e5 1.5px solid;
  }
 .wear-cen{
   position: absolute;
   z-index: 10;
   top: 33%;
   left: 44%;
 }

}



}


