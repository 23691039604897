.st{
  width: 100%;
  height: 280px;
  //position: relative;
  .st-up{
    position: relative;
  }
  .st-down{
    position:  relative;
    top:180px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .num{
      display: inline-block;
      width: 22px;
      height: 22px;
      //background-color: #1abb2c;
      text-align: center;
      border-radius: 50%;
    }
    .st-do-le{
      text-align: left;
    }
    .st-do-ri{
      text-align: right;
    }

  }
}