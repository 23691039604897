.quick-sug{
   padding: 16px 28px;
   display: flex;
   height: 200px;
   //background-color: #cdcdcd;
   width: 100%;
   justify-content: space-between;
}

.tyq-sug{
      background: #fff4e1;
      border-radius: 4px;
      padding: 16px 28px;
      min-width: 400px;
      //height: 200px;
      .cnt {
        font-size: 7px;
      }
    }