.dill-tra-con{
   width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
    margin: auto;

  .dill-tra-img{
    border: 0;
    width: 130px;
    height: 90px;
  }
  .dill-tra-img-sub{
    border: 0;
    width: 43px;
    height: 90px;
  }
}

.dill-tra-lf{
  width: 40%;
  margin-top: 20px;
}

.dill-tra-rf{
  width: 40%;
  margin-top: 20px;
}

.dill-tra-lb{
  width: 40%;
  margin-top: 20px;
}

.dill-tra-rb{
  width: 40%;
  margin-top: 20px;
}
.dill-tra-con-cen{
  position: absolute;
  left: 33%;
  top: 33%;
}