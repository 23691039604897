.ty-wear {
  padding: 16px 28px;

  .ty-color {
    text-align: center;
    img {
      width: 750px;
      height: 30px;
    }
  }


  .ty-wear-con {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
    margin: auto;


    .ty-wear-lf {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      width: 35%;
      margin-top: 20px;
    }

    .ty-wear-rf {
      display: flex;
      justify-content: flex-end;
      align-content: space-between;
      width: 35%;
      margin-top: 20px;
    }

    .ty-wear-lb {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      width: 35%;
      margin-top: 70px;
    }

    .ty-wear-rb {
      display: flex;
      justify-content: flex-end;
      align-content: flex-start;
      width: 35%;
      margin-top: 70px;
    }

    .ty-wear-cen {
      position: absolute;
      margin-top: 20px;
      left: 272px;

    }


    .con-tire {
      width: 99px;
      height: 46px;
    }

    .con-le-con {
      margin-left: 5px;
    }

    .con-num {
      width: 99px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }
}


