
.dill-header{
  position: relative;
  .qrcode{
    position: absolute;
    top:5px;
    right: 3px
  }
  .header-store{
  margin-bottom: 3px;
}
.header-device{
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  .item{
  font-weight: bold;
  width: 50%;
  margin-bottom: 3px;
  .tcl{
    color: #ffad26;
    text-align: left;
    padding-right: 10px;
  }
  .ccl{
    padding-left: 5px;
  }

}

}







}
