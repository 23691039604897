.tyre-con {
  position: relative;

  .tyre-lf {
    img {
      width: 30px;
      height: 70px;
    }

    position: absolute;
    top: 15px;
    left: -6px
  }

  .tyre-rf {
    img {
      width: 30px;
      height: 70px;
    }

    position: absolute;
    left: 96px;
    top: 15px
  }

  .tyre-lb {
    img {
      width: 30px;
      height: 70px;
    }

    position: absolute;
    top: 150px;
    left: -6px
  }

  .tyre-rb {
    img {
      width: 30px;
      height: 70px;
    }

    position: absolute;
    left: 96px;
    top: 150px
  }

  .tyre-car {
    position: absolute;
    margin: auto;
    z-index: 10;

    img {
      width: 120px;
      height: 250px;
    }
  }
}



