.ty-head {
  margin-bottom: 15px;

  .head-bottom-con {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    width: 70%;
    font-size: 14px;
    font-weight: bold;

    .ct {
      color: #ffad26;
    }

    .cn {
      padding-left: 16px;
    }
  }

  .ty-head-qr {
    float: right;

  }
}




